import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { SelectFilters } from './dialogs/select-filters';
PLATFORM.moduleName('./dialogs/select-filters');
import moment from 'moment';

/**
 * A generic filter UI that allows a user to filter results in a data-grid based on a set of columns
 * Look at lead-list.js to see how to configure the columns and dataGridFilters
 */
export class AdvancedColumnFilter {
    static inject = [Element, DialogService];
    _element;
    _dialogService;

    @bindable columns;
    @bindable dataGridFilters;
    @bindable lsKey;
    @bindable removeFilter;
    @bindable clear;
    @bindable restore;

    dateOptions = ['<', '>', 'b'];
    numberOptions = ['>', '>=', '<', '<='];

    constructor(element, dialogService) {
        this._element = element;
        this._dialogService = dialogService;
    }

    attached() {
        this._attached = true;
        this._initialize();
    }

    columnsChanged() {
        if (!this._attached) return;
        if (this._restoring) {
            this._restoring = false;
            return;
        }
        this._initialize();
    }

    clearChanged() {
        if (!this.clear) return;
        this._clearedColumns = JSON.parse(JSON.stringify(this.columns));
        this.columns.forEach(c => this._removeFilter(c, false));
        this._applyFilters();
    }

    restoreChanged() {
        if (!this.restore) return;
        if (!this._clearedColumns) return;
        this._restoring = true;
        this.columns = JSON.parse(JSON.stringify(this._clearedColumns));
        this._clearedColumns = undefined;
        this._applyFilters();
    }

    toggleExpanded(c) {
        c.expanded = !c.expanded;
    }

    /**
     * Initializes the filters and calls the filters-set event so the host page can set it's filters
     * Loads the filters from local storage
     */
    _initialize() {
        if (!this.columns || !this.columns.length) return;

        if (this.lsKey) {
            const lsFiltersValue = localStorage.getItem(this.lsKey);
            let lsFilters = [];
            if (lsFiltersValue) {
                lsFilters = JSON.parse(lsFiltersValue);
                if (lsFilters.length) {
                    // Need to reset all the default values and apply the ones from the previous session
                    this.columns.forEach(c => {
                        c.defaultValue = undefined;
                        const dfcs = lsFilters.filter(x => x.key === c.key);
                        if (!dfcs || !dfcs.length) return;
                        // There was a default saved from before. load that instead of the stock default
                        c.defaultValue = dfcs.map(x => x.value).join('|');
                        if (dfcs.length === 1) c.valueDisplay = dfcs.map(x => x.valueDisplay).join('|');
                        else c.valueDisplay = undefined;

                        if (c.checkboxOptions) {
                            c.checkboxOptions.forEach(co => {
                                co.checked = dfcs.find(x => x.key === c.key && co.value === x.value) !== undefined;
                            });
                        }
                    });
                }
            }
        }

        const columnFilters = [];
        this._hasDefaultValue = false;
        this.columns.forEach(c => {
            if (c.defaultValue) {
                this._hasDefaultValue = true;
                c.isFiltered = true;
                c.expanded = true;
            }
            if (c.options) {
                c.checkboxOptions = [];
                c.options.forEach(o => {
                    let oChecked = false;
                    if (c.defaultValue) {
                        const cDefaultOptions = c.defaultValue.split('|');
                        oChecked = cDefaultOptions.includes(o);
                    }
                    c.checkboxOptions.push({ value: o, checked: oChecked });
                });
            }
            if (c.type === 'date') {
                this._initializeDateView(c);
                if (c.defaultValue) {
                    const values = c.defaultValue.split('|');
                    c.dateOption = values[0];
                    c.dateView = this._dateView(c);
                    if (values.length === 2) {
                        c.value = values[1];
                        c.date = moment(values[1]);
                    } else if (values.length === 3) {
                        c.value = c.defaultValue;
                        c.range = [moment(values[1]), moment(values[2])];
                    } else {
                        c.value = values[0];
                        c.date = moment(values[0]);
                    }
                }
            } else if (c.type === 'number') {
                if (c.defaultValue) {
                    let numberValue = c.defaultValue.trim();
                    if (numberValue.indexOf('>=') === 0) {
                        c.numberOption = '>=';
                        c.number = numberValue.substr(2).trim();
                    } else if (numberValue.indexOf('<=') === 0) {
                        c.numberOption = '<=';
                        c.number = numberValue.substr(2).trim();
                    } else if (numberValue.indexOf('<') === 0) {
                        c.numberOption = '<';
                        c.number = numberValue.substr(1).trim();
                    } else if (numberValue.indexOf('>') === 0) {
                        c.numberOption = '>';
                        c.number = numberValue.substr(1).trim();
                    } else {
                        c.numberOption = values[0];
                        c.number = numberValue;
                    }
                }
            }
            columnFilters.push({ key: c.key, value: '', custom: (filterValue, row) => {
                if (!filterValue) return true;
                const rowValue = c.type === 'number' ? row[c.key] : row[c.key]?.toLowerCase() ?? '';
                if (c.type === 'string') {
                    if (c.allowMultiple) {
                        const showValues = filterValue.split(c.multipleDelimiter);
                        return showValues.includes(rowValue);
                    }
                    return rowValue.indexOf(filterValue.toLowerCase()) >= 0;
                } else if (c.type === 'options') {
                    const showOptions = filterValue.toLowerCase().split('|');
                    return showOptions.includes(rowValue);
                } else if (c.type === 'number') {
                    // allow >, < or a number
                    if (filterValue.indexOf('<=') === 0) {
                        const lte = Number(filterValue.substr(2).trim());
                        if (isNaN(lte)) return false;
                        return rowValue <= lte;
                    } else if (filterValue.indexOf('<') === 0) {
                        const lt = Number(filterValue.substr(1).trim());
                        if (isNaN(lt)) return false;
                        return rowValue < lt;
                    } else if (filterValue.indexOf('>=') === 0) {
                        const gte = Number(filterValue.substr(2).trim());
                        if (isNaN(gte)) return false;
                        return rowValue >= gte;
                    } else if (filterValue.indexOf('>') === 0) {
                        const gt = Number(filterValue.substr(1).trim());
                        if (isNaN(gt)) return false;
                        return rowValue > gt;
                    } else {
                        const e = Number(filterValue.trim());
                        if (isNaN(e)) return false;
                        return rowValue == e;
                    }
                } else if (c.type === 'date') {
                    if (c.dateOption === '<') {
                        return moment(rowValue).isBefore(moment(c.date).startOf('day'));
                    }
                    if (c.dateOption === '>') {
                        return moment(rowValue).isAfter(moment(c.date).startOf('day'));
                    }
                    if (c.dateOption === 'b') {
                        return moment(rowValue).isBetween(moment(c.range[0]).startOf('day'), moment(c.range[1]).endOf('day'));
                    }
                }
                return false;
            }});
        });
        this._element.dispatchEvent(new CustomEvent('columns-set', { bubbles: true, detail: columnFilters }));
    }

    openFilters() {
        const model = {
            columns: this.columns,
            filters: this.filters,
        }
        this._dialogService.open({ viewModel: SelectFilters, model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled) return;
            this.columns = response.output.columns;
            this._applyFilters();
        });
    }

    removeFilterChanged() {
        if (!this.removeFilter) return;
        try {
            const column = this.columns.find(x => x.key === this.removeFilter.key);
            if (!column) return;
            if (column.type === 'options') {
                const coIndex = column.checkboxOptions.findIndex(x => x.value === this.removeFilter.value);
                if (coIndex >= 0) {
                    column.checkboxOptions[coIndex].checked = false;
                }
                const checkedOptions = column.checkboxOptions.filter(x => x.checked);
                column.value = checkedOptions.map(x => x.value).join('|');
            } else if (column.type === 'date') {
                if (column.dateView = this._dateView(column));
                if (column.dateView === 'single') {
                    this.singleDateSet(column, { date: undefined });
                } else if (column.dateView === 'range') {
                    this.dateRangeSet(column, { range: undefined });
                }
            } else if (column.type === 'number') {
                column.numberOption = this.numberOptions[0];
                column.number = '';
                column.value = '';
            } else {
                column.value = '';
            }
            this._applyFilters();
        } catch (err) {
            console.log(err);
        }
    }

    _removeFilter(c, applyChanges = true) {
        try {
            const column = this.columns.find(x => x.key === c.key);
            if (column.type === 'options') {
                column.checkboxOptions.forEach(o => o.checked = false);
                column.value = '';
            } else if (column.type === 'date') {
                if (column.dateView === 'single') {
                    this.singleDateSet(column, { date: undefined });
                } else if (column.dateView === 'range') {
                    this.dateRangeSet(column, { range: undefined });
                }
            } else {
                // string, number
                column.value = '';
            }
            if (applyChanges) {
                this._applyFilters();
            }
        } catch (err) {
            console.log(err);
        }
    }

    stringChanged(column) {
        this._applyFilters();
    }

    numberOptionChanged(column) {
        this.numberChanged(column);
    }

    numberChanged(column) {
        if (column.number === undefined || column.number === null || column.number === '') {
            column.value = '';
        } else {
            column.value = `${column.numberOption}${column.number}`;
        }
        this._applyFilters();
    }

    optionChecked(column, option, checked) {
        const cValueArray = column.value && column.value.trim().length > 0 ? column.value.split('|') : [];
        if (checked) {
            cValueArray.push(option.value);
        } else {
            const idx = cValueArray.indexOf(option.value);
            if (idx >= 0) cValueArray.splice(idx, 1);
        }
        column.value = cValueArray.join('|');
        this._applyFilters();
    }

    _initializeDateView(col) {
        if (col.dateView) return;
        if (!col.initializeValue) return;
        const initializeValues = col.initializeValue.split('|');
        col.dateOption = initializeValues[0];
        col.dateView = this._dateView(col);
        col.initialValue = moment().add(Number(initializeValues[1]), 'days').toISOString();
    }

    _dateView(col) {
        if (col.dateOption === '<' || col.dateOption === '>') return 'single';
        if (col.dateOption === 'b') return 'range';
        return '';
    }

    dateOptionChanged(column) {
        const newDateView = this._dateView(column);
        if (column.dateView === 'single') {
            this.singleDateSet(column, { date: undefined });
        } else if (column.dateView === 'range') {
            this.dateRangeSet(column, { range: undefined });
        }
        if (newDateView === 'single' && column.dateView === 'single') {
            // staying a single date view, just changing the order
            column.dateView = newDateView;
            this.singleDateSet(column, { date: column.date });
        } else {
            // changed from a single to a date range or range to single
            column.value = undefined;
            column.date = undefined;
            column.range = undefined;
            column.dateView = newDateView;
            return;
        }
    }

    singleDateSet(c, data) {
        if (data.date && (moment.isMoment(data.date) || moment(data.date).isValid())) {
            let valueDisplay;
            let valueDate1;
            let valueDate2;
            if (c.dateOption === '<') {
                valueDisplay = `< ${moment(data.date).format('l')}`;
                valueDate1 = moment(data.date).startOf('day').toISOString();
            } else if (c.dateOption === '>') {
                valueDisplay = `> ${moment(data.date).format('l')}`;
                valueDate1 = moment(data.date).endOf('day').toISOString();
            } else if (c.dateOption === 'b') {
                valueDisplay = `${moment(data.date).format('l')} - ${moment(data.date).format('l')}`;
                valueDate1 = moment(data.date).startOf('day').toISOString();
            }
            const valueArr = [];
            valueArr.push(c.dateOption);
            valueArr.push(valueDate1);
            if (valueDate2) valueArr.push(valueDate2);
            c.value = valueArr.join('|');
            c.valueDisplay = valueDisplay;
        } else {
            c.date = undefined;
            c.value = '';
        }
        this._applyFilters();
    }

    dateRangeSet(c, data) {
        if (Array.isArray(data.range) && data.range.length === 2 && moment.isMoment(data.range[0]) && data.range[0].isValid() && moment.isMoment(data.range[1]) && data.range[1].isValid()) {
            c.value = `${c.dateOption}|${data.range[0].toISOString()}|${data.range[1].toISOString()}`;
            c.valueDisplay = `${data.range[0].format('l')} - ${data.range[1].format('l')}`;
        } else {
            c.range = undefined;
            c.value = '';
        }
        this._applyFilters();
    }

    clearAll() {
        this.columns.forEach(column => {
            this._removeFilter(column);
        });
    }

    dataGridFiltersChanged() {
        if (!this.dataGridFilters) return;
        if (this._hasDefaultValue) {
            this.columns.forEach(c => {
                if (c.defaultValue && c.type !== 'date') {
                    c.value = c.defaultValue;
                }
            });
            this._hasDefaultValue = false;
            window.setTimeout(() => this._applyFilters(), 500);
        }
    }

    /**
     * The host page binds its filters to data-grid-filters
     * When the filters are applied, the dataGridFilters are updated
     */
    _applyFilters() {
        try {
            this.columns.forEach(c => {
                this.dataGridFilters.find(x => x.key === c.key).value = c.value || '';
                c.isFiltered = c.value ? true : false;
            });
            const filteredBy = this.columns.filter(x => x.value);
            this.filteredBy = [];
            filteredBy.forEach(fb => {
                if (fb.type === 'options') {
                    const fbValues = fb.value.split('|');
                    fbValues.forEach(fbv => {
                        const cloneOptionFb = JSON.parse(JSON.stringify(fb));
                        cloneOptionFb.value = fbv;
                        this.filteredBy.push(cloneOptionFb);
                    });
                } else {
                    // string, number
                    const cloneFb = JSON.parse(JSON.stringify(fb));
                    this.filteredBy.push(cloneFb);
                }
            });
            const filtered = this.filteredBy.length > 0;
            const displayFilteredBy = [];
            this.filteredBy.forEach(f => {
                if (f.type === 'date') {
                    displayFilteredBy.push({ key: f.key, labelKey: f.labelKey, value: f.valueDisplay });
                } else {
                    displayFilteredBy.push({ key: f.key, labelKey: f.labelKey, value: f.value });
                }
            });
            this._element.dispatchEvent(new CustomEvent('filtered', { bubbles: true, detail: { filtered, filteredBy: displayFilteredBy } }));

            if (this.lsKey) localStorage.setItem(this.lsKey, JSON.stringify(this.filteredBy));
        } catch (err) {
            console.log(err);
        }
    }
}