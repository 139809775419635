import { bindable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import Dropzone from 'dropzone';
import { Notifier, Page } from 'common/ui';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { FilesFor as FilesForService } from 'services/files-for';
import { Api } from 'common/server';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import environment from '../../../../config/environment.json';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
import { c } from 'common/common';

export class FilesFor {
    static inject = [Element, EventAggregator, DialogService, Notifier, Page, Security, I18n, FilesForService, Api]
    _ea;
    _dialogService;
    _notifier;
    _page;
    _security;
    _i18n;
    _filesFor;
    _api;

    @bindable({ changeHandler: '_initialize' }) forType;
    @bindable({ changeHandler: '_initialize' }) forId;
    @bindable showTitle = true;
    @bindable theme = 'light';
    @bindable showExpander = true;
    @bindable view = 'standard';
    @bindable inlineFileType;
    @bindable inlineFileTypeIcon;
    @bindable inlineFileTypeTitle;
    @bindable inlineFileTypeDefaultTitle;
    @bindable displayInlineFile;
    @bindable toolbarIcon = 'fa-duotone fa-square-ellipsis';
    dropzoneEl;
    formAction;
    dropzone = null;
    isAttached = false;
    inlineFileSrc = 'about:blank';

    constructor(element, eventAggregator, dialogService, notifier, page, security, i18n, filesFor, api) {
        this._element = element;
        this._ea = eventAggregator;
        this._dialogService = dialogService;
        this._notifier = notifier;
        this._page = page;
        this._security = security;
        this._i18n = i18n;
        this._filesFor = filesFor;
        this._api = api;
    }

    @computedFrom('isAttached', 'forType', 'forId')
    get canLoad() {
        return this.isAttached && this.forType && this.forId;
    }

    attached() {
        this.isAttached = true;
        if (this.showExpander === false) this.showDetails = true;
        this._initialize();
    }

    detached() {
        if (!this.dropzone) return;
        try {
            this.dropzone.destroy();
            this.dropzone = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async _initialize() {
        if (!this.canLoad) return;
        // First need to clean up the existing dropzone if it is there
        this.detached();
        this._setFormAction();
        this._initializeDropzone();
        await this._load();
    }

    _setFormAction() {
        if (!this.canLoad) return;
        const qs = [];
        qs.push(`forType=${encodeURIComponent(this.forType)}`);
        qs.push(`forId=${encodeURIComponent(this.forId)}`);
        if (this.fileType) qs.push(`fileType=${encodeURIComponent(this.fileType)}`);
        this.formAction = `${environment.api}/api/file-for/upload?${qs.join('&')}`;
        if (this.dropzone) this.dropzone.options.url = this.formAction;
    }

    async _load() {
        try {
            this.files = [];
            this.inlineFileSrc = 'about:blank';
            this.files = await this._filesFor.list(this.forType, this.forId);

            if (this.inlineFileType) {
                this.files.forEach(f => {
                    if (f.fileType === this.inlineFileType) {
                        f.title = this.inlineFileTypeTitle ? this._i18n.tr(this.inlineFileTypeTitle) : f.displayName ?? f.fileName;
                        f.icon = this.inlineFileTypeIcon ?? f.icon;
                    } else {
                        f.title = f.displayName ?? f.fileName;
                    }
                });
                const idx = this.files.findIndex(x => x.fileType === this.inlineFileType);
                if (idx >= 0) {
                    this.viewFileInline(idx, this.files[idx], this.inlineFileTypeTitle);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    cancelUpload() {
        this.selectFileType(undefined);
    }

    selectFileType(fileType) {
        this.fileType = fileType;
        this._setFormAction();
    }

    async viewFile(file) {
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: this._filesFor.viewSrc(file.id), fullHeight: true, zIndex: 2010 });
        return false;
    }

    async downloadFile(file) {
        try {
            const model = { id: file.id };
            await this._page.export(this._api, 'file-for/export-file', model);
        } catch (error) {
            this._notifier.generalError();
        }
        return false;
    }

    async renameFile(file) {
        try {
            const model = { key: 'rename-file', okButtonClass: 'btn-primary', messageObject: { name: file.displayName }, inputLabel: 'name', inputValue: file.displayName };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                await this._filesFor.rename(file.id, response.output.value);
                file.displayName = response.output.value;
            });
        } catch (err) {
            console.log(err);
        }
    }

    async deleteFile(index, file) {
        try {
            await this._filesFor.delete(file.id, this.forId);
            if (index >= 0) this.files.splice(index, 1);
            if (this.view === 'inline') {
                if (this.files.length) {
                    this.viewFileInline(0, this.files[0]);
                } else if (this._initialDisplayInlineFile) {
                    this.displayInlineFile = this._initialDisplayInlineFile;
                }
            }
        } catch (error) {
            this._notifier.generalError();
        }
        return false;
    }

    inlineAction(key) {
        switch (key) {
            case 'delete-inline-file':
                this.deleteFile(this.inlineFileIndex, this.inlineFile);
                break;
            default: this.selectFileType(key); break;
        }
    }

    displayInlineFileChanged() {
        if (!this.displayInlineFile) return;
        if (this.displayInlineFile.initialLoad) this._initialDisplayInlineFile = JSON.parse(JSON.stringify(this.displayInlineFile));
        if (this.displayInlineFile.initialLoad && this.inlineFileOfType && this.files.findIndex(x => x.fileType === this.inlineFileType) >= 0) return;
        this.viewFileInline(undefined, { url: this.displayInlineFile.src }, this.inlineFileTypeDefaultTitle);
    }

    viewFileInline(index, file, title) {
        this.inlineFileIndex = index;
        this.inlineFile = file;
        this.inlineFileTitle = title ?? file.title;
        this.inlineFileSrc = file.url || 'about:blank';
        const deleteIdx = this.inlineActions.findIndex(x => x.key === 'delete-inline-file');
        if (file.id) {
            if (deleteIdx < 0) this.inlineActions.push({ key: 'delete-inline-file', name: this._i18n.tr('delete'), icon: 'fa-duotone fa-trash-can' });
        } else {
            if (deleteIdx >= 0) this.inlineActions.splice(deleteIdx, 1);
        }
    }

    openFile() {
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: this.inlineFileSrc, fullHeight: true, zIndex: 2010 });
    }

    _initializeDropzone() {
        this.dropzone = undefined;
        if (!this.canLoad) return;

        const me = this;
        let d = new Date();
        const timezoneOffset = d.getTimezoneOffset();
        const settings = this._filesFor.settings[this.forType] || {extensions:['.pdf'],maxFileMb:50,fileTypes:[]};
        this.fileExtensions = settings.extensions.join(', ');
        this.fileSize = settings.maxFileMb;
        this.fileTypes = settings.fileTypes ?? [];
        this.inlineActions = [];
        this.fileTypes.forEach(ft => this.inlineActions.push({ key: ft, name: this._i18n.tr(`file-for-type-${ft}`), icon: 'fa-duotone fa-cloud-arrow-up' }));

        this.dropzone = new Dropzone(this.dropzoneEl, {
            url: this.formAction,
            paramName: 'file',
            maxFilesize: settings.maxFileMb, // MB
            headers: { 'Authorization': `Bearer ${this._security.token}`, 'X-LEGACY-TimezoneOffset': timezoneOffset },
            acceptedFiles: settings.extensions.join(','),
            maxFiles: 1,
            timeout: 180000,
            init: function() {
                this.on('addedfile', function(file) {
                    me.processing = true;
                });
                this.on('success', function(file, response) {
                    this.removeFile(file);
                    me._notifier.success('file-for-upload-success');
                    me.processing = false;
                    me.cancelUpload(undefined);
                    me._load();
                    me._element.dispatchEvent(new CustomEvent('uploaded', { bubbles: true, detail: {} }));
                    me._ea.publish(c.EventKeys.fileFor.updated(me.forId));
                });
                this.on('error', function(file, error, x) {
                    this.removeAllFiles();
                    me._notifier.error('my-files-upload-failed', true);
                    me.isProcessing = false;
                });
            }
        });
    }
}

