import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { FileManagerDialog } from './file-manager-dialog';
PLATFORM.moduleName('./file-manager-dialog');

export class FileManager {
    static inject = [Element, EventAggregator, DialogService];
    _element;
    _ea;
    _dialogService;

    @bindable showIcon = false;

    _handlers = [];

    constructor(element, ea, dialogService) {
        this._element = element;
        this._ea = ea;
        this._dialogService = dialogService;
    }

    attached() {
        this._handlers.push(this._ea.subscribe('lpfn-open-file-manager', (data) => this.openFileManager(data)));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
    }

    openFileManager(data) {
        const fileType = data ? data.fileType : 'all';
        this._dialogService.open({ viewModel: FileManagerDialog, model: { fileType }, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            this._element.dispatchEvent(new CustomEvent('fileSelected', { bubbles: true, detail: response.output }));
        });
    }
}
