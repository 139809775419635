import { observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { LpfnUtil } from 'common/utils';
import moment from 'moment';

export class SelectFilters {
    static inject = [DialogController];
    dialogController;

    @observable addFilterKey = null;
    dateOptions = ['<', '>', 'b'];

	constructor(dialogController) {
        this.dialogController = dialogController;
	}

    activate(model) {
        this.columns = model.columns;
        this.filters = model.filters;
        this._initialize();
    }

    async _initialize() {
        try {
            this.filteredColumns = [];
            this.columns.forEach(c => {
                if (c.type === 'date') {
                    this._initializeDateView(c);
                }
                if (!c.value) return;
                if (c.type === 'options') {
                    const values = c.value.split('|');
                    values.forEach(v => {
                        const clone = JSON.parse(JSON.stringify(c));
                        clone.value = v;
                        this.filteredColumns.push(clone);
                    });
                } else if (c.type === 'date') {
                    const dateClone = JSON.parse(JSON.stringify(c));
                    const values = dateClone.value.split('|');
                    dateClone.dateOption = values[0];
                    dateClone.dateView = this._dateView(dateClone);
                    if (values.length === 2) {
                        dateClone.value = values[1];
                    } else {
                        dateClone.range = [moment(values[1]), moment(values[2])];
                    }
                    this.filteredColumns.push(dateClone);
                }
            });
            this._setAddFilterOptions();
        } catch (err) {
            console.log(err);
        }
    }

    dateOptionChanged(fc) {
        const newDateView = this._dateView(fc);
        if (newDateView !== fc.dateView) {
            // need to clear out the value
            fc.value = undefined;
            fc.range = undefined;
        }
        fc.dateView = newDateView;
    }

    _initializeDateView(col) {
        if (col.dateView) return;
        if (!col.initializeValue) return;
        const initializeValues = col.initializeValue.split('|');
        col.dateOption = initializeValues[0];
        col.dateView = this._dateView(col);
        col.initialValue = moment().add(Number(initializeValues[1]), 'days').toISOString();
    }

    _dateView(col) {
        if (col.dateOption === '<' || col.dateOption === '>') return 'single';
        if (col.dateOption === 'b') return 'range';
        return '';
    }

    _setAddFilterOptions() {
        this.addFilterOptions = [];
        this.columns.forEach(c => {
            if (c.value) {
                if (c.type === 'options') {
                    const filteredValues = c.value.split('|');
                    c.options.forEach(o => {
                        if (!filteredValues.includes(o)) {
                            if (this.addFilterOptions.find(x => x.key === c.key)) return; // only want 1 for each key
                            this.addFilterOptions.push(c);
                        }
                    });
                } else {
                    // the filter has already been set, do not allow another filter for this one
                }
                return;
            }
            this.addFilterOptions.push(c);
        });
    }

    addFilterKeyChanged() {
        if (!this.addFilterKey) return;
        const addFilter = this.columns.find(x => x.key === this.addFilterKey);
        if (addFilter.type === 'date' && addFilter.initialValue) {
            addFilter.value = addFilter.initialValue;
        }
        this.filteredColumns.push(JSON.parse(JSON.stringify(addFilter)));
        this._setAddFilterOptions();
        window.setTimeout(() => this.addFilterKey = '', 100);
    }

    removeFilter(index) {
        this.filteredColumns[index].value = '';
        if (this.filteredColumns[index].type === 'date') {
            this._initializeDateView(this.filteredColumns[index]);
        }
        this.filteredColumns.splice(index, 1);
    }

	async applyFilters() {
		try {
            // cloned the columns when adding so multiple options can be added, so need to set the actual columns
            this.columns.forEach(c => {
                const filters = this.filteredColumns.filter(x => x.key === c.key);
                if (!filters || !filters.length) {
                    c.value = '';
                    return;
                }
                if (c.type === 'options') {
                    c.value = LpfnUtil.uniqueArray(filters.map(f => f.value)).join('|');
                } else if (c.type === 'date') {
                    const dateFilter = filters[0];
                    if (dateFilter.dateView === 'single') {
                        if (dateFilter.value && (moment.isMoment(dateFilter.value) || moment(dateFilter.value).isValid())) {
                            let valueDisplay;
                            let valueDate1;
                            let valueDate2;
                            if (dateFilter.dateOption === '<') {
                                valueDisplay = `< ${moment(dateFilter.value).format('l')}`;
                                valueDate1 = moment(dateFilter.value).startOf('day').toISOString();
                            } else if (dateFilter.dateOption === '>') {
                                valueDisplay = `> ${moment(dateFilter.value).format('l')}`;
                                valueDate1 = moment(dateFilter.value).endOf('day').toISOString();
                            } else if (dateFilter.dateOption === 'b') {
                                valueDisplay = `${moment(dateFilter.value).format('l')} - ${moment(dateFilter.value).format('l')}`;
                                valueDate1 = moment(dateFilter.value).startOf('day').toISOString();
                            }
                            const valueArr = [];
                            valueArr.push(dateFilter.dateOption);
                            valueArr.push(valueDate1);
                            if (valueDate2) valueArr.push(valueDate2);
                            c.value = valueArr.join('|');
                            c.valueDisplay = valueDisplay;
                        } else {
                            c.value = '';
                        }
                    } else {
                        // Date range
                        if (Array.isArray(dateFilter.range) && dateFilter.range.length === 2 && moment.isMoment(dateFilter.range[0]) && dateFilter.range[0].isValid() && moment.isMoment(dateFilter.range[1]) && dateFilter.range[1].isValid()) {
                            c.value = `${dateFilter.dateOption}|${dateFilter.range[0].toISOString()}|${dateFilter.range[1].toISOString()}`;
                            c.valueDisplay = `${dateFilter.range[0].format('l')} - ${dateFilter.range[1].format('l')}`;
                        } else {
                            c.value = '';
                        }
                    }
                } else {
                    c.value = filters[0].value;
                }
            });
            this.dialogController.ok({ columns: this.columns });
        } catch (err) {
            console.log(err);
        }
	}
}
