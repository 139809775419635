import { PLATFORM } from 'aurelia-pal';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Files, fileIcon, fileIsImage } from 'services/files';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import environment from '../../../../config/environment.json';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class FileManagerDialog {
    static inject = [Element, DialogController, DialogService, EventAggregator, Files];
    _element;
    _dialogService;
    dialogController;
    _ea;
    _files;

    fileType;
    searchOn;
    pageSize = 25;
    filters = [
        { value: '', keys: ['fileName', 'displayName', 'tags'] },
        { value: 'all', custom: this.fileTypeFilter },
    ];
    fileIcon = fileIcon;
    fileIsImage = fileIsImage;
    fileUploadUrl = `${environment.api}/api/file/upload`;
    dropzoneWithFileName = true;

    _handlers = [];

    constructor(element, dialogController, dialogService, ea, files) {
        this._element = element;
        this.dialogController = dialogController;
        this._dialogService = dialogService;
        this._ea = ea;
        this._files = files;
    }

    activate(model) {
        this.fileType = model ? model.fileType || 'all' : 'all';
        this.filters[1].value = this.fileType;
    }

	attached() {
        this._handlers.push(this._ea.subscribe('lpfn-close-file-manager', () => this._closeFileManager()));
        this.loadFiles();
        window.setTimeout(() => {
            try {
                this._element.closest('ux-dialog-container').style.zIndex = 999998;
            } catch (err) {
                console.log(err);
            }
        }, 0);
    }

	detached() {
        this._handlers.forEach(h => h.dispose());
	}

    async loadFiles() {
        try {
            this.loading = true;

            this.files = await this._files.list(this.searchOn);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    fileTypeFilter(filterValue, row) {
        switch (filterValue) {
            case 'image':
                return row.mediaType.indexOf('image') === 0;
            case 'file':
                return row.mediaType.indexOf('image') !== 0;
            default:
                return true;
        }
    }

    selectFileType(fileType) {
        this.fileType = fileType;
        this.filters[1].value = fileType;
    }

    selectFile(file) {
        this.dialogController.ok(file);
    }

    async renameFile(file) {
        try {
            const model = { key: 'rename-file', okButtonClass: 'btn-primary', messageObject: { name: file.displayName }, inputLabel: 'name' };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                await this._files.rename(file.id, response.output.value);
                file.displayName = response.output.value;
            });
        } catch (err) {
            console.log(err);
        }
    }

    async deleteFile(index, file) {
        try {
            const model = { key: 'mbco-delete-file', okButtonClass: 'btn-danger', messageObject: { name: file.displayName || file.fileName } };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                await this._files.delete(file.id);
                this.files.splice(index, 1);
            });
        } catch (err) {
            console.log(err);
        }
    }

    uploadFile() {
        if (this.showDropzone) {
            this.showDropzone = false;
            return;
        }
        this.dropzoneWithFileName = true;
        this.fileUploadUrl = `${environment.api}/api/file/upload`;
        this.showDropzone = true;
    }

    updateFile(file) {
        this.dropzoneWithFileName = false;
        this.fileUploadUrl = `${environment.api}/api/file/upload?fileId=${encodeURIComponent(file.id)}`;
        this.showDropzone = true;
    }

    fileUploaded() {
        this.loadFiles();
        this.showDropzone = false;
    }

    _closeFileManager() {
        this.dialogController.ok();
    }

    hideDropzone() {
        this.showDropzone = false;
    }
}
