import {inject, customElement, bindable} from 'aurelia-framework';
import {I18n} from 'common/i18n';
import {Page} from 'common/ui';
import {Api} from 'common/server';
import { c } from 'common/common';

@customElement('flex-grid')
@inject(Element, I18n, Page, Api)
export class GridView {
    @bindable columns;
    @bindable data;
    @bindable emptyMessage;
    @bindable exportApi;
    @bindable rowClickable;
    @bindable loading;
    @bindable maxHeight;

    _element;
    _i18n;
    _page;
    _api;

    isLoading = true;
    exportTitle;
    isActionClick;

    constructor(element, i18n, page, api) {
        this._element = element;
        this._i18n = i18n;
        this._page = page;
        this._api = api;

        this.exportTitle = this._i18n.tr('flex-grid-export-title');
    }

    loadingChanged() {
        this.isLoading = true;
    }

    dataChanged() {
        if (!this.data) return;
        this.isLoading = false;
    }

    getCellCssClass(datum, column) {
        let value = datum.values.find(x => x.key === column.key);
        if (!value || !value.cssClass) return '';
        return value.cssClass;
    }

    getCellValue(datum, column, forTitle) {
        let value = datum.values.find(x => x.key === column.key);
        if (!value) return '';
        let prefix = column.metadata && column.metadata.prefix ? column.metadata.prefix : '';
        let postfix = column.metadata && column.metadata.postfix ? column.metadata.postfix : '';
        if (column.type === 'date') return `${prefix}${this._i18n.formatDate(value.value, column.metadata ? column.metadata.formatter : null)}${postfix}`;
        if (column.type === 'number') return `${prefix}${c.Helpers.formatNumber(value.value, column.metadata ? column.metadata.formatter : null)}${postfix}`;
        if (column.type === 'money') return `$${c.Helpers.formatMoney(value.value)}`;
        if (column.type === 'string') return value.value ? `${prefix}${value.value}${postfix}` : '';
        if (column.type === 'bool') return `${prefix}${this._i18n.boolToYesNo(value.value)}${postfix}`;
        if (column.type === 'member') return forTitle ? value.value.fullName : value.value;
        return `${prefix}${value.value}${postfix}`;
    }

    onAction(datum, column) {
        this.isActionClick = true;
        let id = datum.values.find(x => x.key === 'id');
        if (!id) return;

        let actionEvent = new CustomEvent('action', { detail: { id: id.value, action: column.key }, bubbles: true});
        this._element.dispatchEvent(actionEvent);
    }

    getCellType(key) {
        let column = this.columns.find(x => x.key === key);
        if (!column) return '';
        return column.type;
    }

    onRowClick(data) {
        if (this.isActionClick) {
            this.isActionClick = false;
            return;
        }
        if (!this.rowClickable) return;
        let id = data.values.find(x => x.key === 'id');
        if (!id) return;

        let rowClickedEvent = new CustomEvent('rowclick', { detail: { id: id.value, data: data }, bubbles: true});
        this._element.dispatchEvent(rowClickedEvent);
    }

    onDownload() {
        let qs = this.exportApi.qs || '';
        this._page.exportData(this._api, this.exportApi.url + '-key?' + qs, this.exportApi.url, null)
            .then(response => { /*nothing...already handled*/ })
            .catch(error => { console.log(error); });
    }
}
